const customSelects = document.querySelectorAll(".custom-select");

if (customSelects) {
  customSelects.forEach((customSelect) => {
    const selectBtn = customSelect.querySelector(".select-button");
    const optionsList = customSelect.querySelectorAll(
      ".select-dropdown li input"
    );
    const selectedValue = customSelect.querySelector(".selected-value");

    selectBtn.addEventListener("click", () => {
      customSelect.classList.toggle("active");
      selectBtn.setAttribute(
        "aria-expanded",
        selectBtn.getAttribute("aria-expanded") === "true" ? "false" : "true"
      );
    });

    if (optionsList) {
      optionsList.forEach((option) => {
        function handler(e) {
          selectedValue.textContent = this.dataset.label;
          customSelect.classList.remove("active");
          customSelect.closest("form").submit();
        }

        option.addEventListener("change", handler);
      });
    }
  });
}
