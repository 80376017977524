import Swiper from "swiper";
import { Pagination, Navigation } from "swiper/modules";
import "./components/expanded-list";
import "./components/custom-select";
import "./components/opinions-slider";
import { elementIsVisibleInViewport } from "./misc/functions";
const activeStepTab = document.querySelector(".thwmscf-tab .active");
const stepNumber = document.querySelector(".cart-header .step-number");
const pageTitle = document.querySelector(".cart-header .h4");

const actionButtons = document.querySelectorAll(
  ".thwmscf-buttons input[type='button']"
);

jQuery(document).ajaxComplete(function (event, xhr, settings) {
  if (settings.url === "/?wc-ajax=remove_coupon") {
    setTimeout(() => {
      location.replace("/koszyk");
    }, 300);
  }
});

if (activeStepTab && stepNumber && actionButtons) {
  actionButtons.forEach((button) => {
    const activeTabNumber = parseFloat(activeStepTab.dataset.step) + 1;
    stepNumber.innerHTML = activeTabNumber;
    if (activeTabNumber === 1) {
      pageTitle.innerHTML = "Logowanie";
    } else if (activeTabNumber === 2) {
      pageTitle.innerHTML = "Dane kontaktowe";
    } else if (activeTabNumber === 3) {
      pageTitle.innerHTML = "Metoda płatności";
    }
    button.addEventListener("click", () => {
      const activeTab = document.querySelector(".thwmscf-tab .active");
      const activeTabNumber = parseFloat(activeTab.dataset.step) + 1;
      stepNumber.innerHTML = activeTabNumber;
      if (activeTabNumber === 1) {
        pageTitle.innerHTML = "Logowanie";
      } else if (activeTabNumber === 2) {
        pageTitle.innerHTML = "Dane kontaktowe";
      } else if (activeTabNumber === 3) {
        pageTitle.innerHTML = "Metoda płatności";
      }
    });
  });
}

const checkoutLoginInputs = document.querySelectorAll(
  ".checkout-login__inputs__item input"
);

const checkoutLoginForm = document.querySelector(".checkout-login__form");
const checkoutLoginInfo = document.querySelector(".checkout-login__info");

if (checkoutLoginInputs) {
  checkoutLoginInputs.forEach((input) => {
    input.addEventListener("change", () => {
      if (input.value === "has-account") {
        checkoutLoginForm.style.display = "block";
        checkoutLoginInfo.style.display = "none";
      } else if (input.value === "no-account") {
        checkoutLoginForm.style.display = "none";
        checkoutLoginInfo.style.display = "flex";
      }
    });
  });
}

const nextBtn = document.querySelector("#action-next");

const requiredFields = document.querySelectorAll(".validate-required");

if (requiredFields && nextBtn) {
  requiredFields.forEach((field) => {
    const input = field.querySelector("input");
    if (input) input.setAttribute("required", true);
  });
  const form = document.querySelector("form.woocommerce-checkout");

  nextBtn.addEventListener("click", () => {
    form.reportValidity();
    requiredFields.forEach((field) => {
      const input = field.querySelector("input");
      if (input) {
        if (input.getAttribute("required") && !input.value) {
          input.style.border = "1px solid red";
        } else {
          input.style.border = "";
        }
      }
    });
  });
}
const packages = document.querySelector("#packages");

if (packages) {
  const slides = document.querySelectorAll(".swiper-slide");

  const packagesSlider = new Swiper(".product-packages__items", {
    modules: [Pagination],
    loop: false,
    slidesPerView: 1.1,
    spaceBetween: 20,
    centeredSlides: true,
    breakpoints: {
      540: {
        slidesPerView: 1.5,
      },
      740: {
        slidesPerView: 2,
        centeredSlides: false,
      },
      1100: {
        slidesPerView: 3,
        centeredSlides: false,
      },
    },
    pagination: {
      el: ".product-packages__pagination",
      clickable: true,
      renderBullet: function (index, className) {
        return (
          '\
          <div class="pag-container ' +
          className +
          '">\
          <p class="pag-text p--small p--bold">' +
          slides[index].dataset.name.split(" ").pop() +
          "</p>\
          </div>"
        );
      },
    },
  });
}

const saleDateWrappers = document.querySelectorAll(
  ".product-package__footer__promo-time"
);

if (saleDateWrappers) {
  saleDateWrappers.forEach((saleDateWrapper) => {
    const end = new Date(saleDateWrapper.dataset.date);

    const _second = 1000;
    const _minute = _second * 60;
    const _hour = _minute * 60;
    const _day = _hour * 24;
    let timer;

    function showRemaining() {
      const now = new Date();
      const distance = end - now;
      if (distance < 0) {
        clearInterval(timer);
        saleDateWrapper.querySelector("countdown").innerHTML =
          "Promocja zakończona!";

        return;
      }
      const days = Math.floor(distance / _day);
      const hours = Math.floor((distance % _day) / _hour);
      const minutes = Math.floor((distance % _hour) / _minute);
      const seconds = Math.floor((distance % _minute) / _second);

      saleDateWrapper.querySelector(".countdown__days").innerHTML = days;
      saleDateWrapper.querySelector(".countdown__days__name").innerHTML =
        days === 1 ? "dzień" : "dni";
      saleDateWrapper.querySelector(".countdown__hours").innerHTML = hours;
      saleDateWrapper.querySelector(".countdown__minutes").innerHTML = minutes;
      saleDateWrapper.querySelector(".countdown__seconds").innerHTML = seconds;
    }

    timer = setInterval(showRemaining, 1000);
  });
}

const promoCheckbox = document.querySelector(".promo-checkbox input");

if (promoCheckbox) {
  promoCheckbox.addEventListener("change", () => {
    promoCheckbox.closest("form").submit();
  });
}

const productItemsWrapper = document.querySelector(".product-list__items");
const productListEmpty = document.querySelector(".product-list__empty");

if (productItemsWrapper) {
  if (!productItemsWrapper.children.length) {
    productListEmpty.style.display = "block";
  }
}

const optionsWrapper = document.querySelector("#options");
const floatingBtn = document.querySelector(".floating-options-button");

if (optionsWrapper) {
  window.addEventListener("scroll", () => {
    if (elementIsVisibleInViewport(optionsWrapper)) {
      floatingBtn.style.display = "none";
    } else {
      floatingBtn.style.display = "flex";
    }
  });
}

const crossSellProductDescriptions = document.querySelectorAll(
  ".cross-sell-product__description__content"
);

if (crossSellProductDescriptions) {
  crossSellProductDescriptions.forEach((description) => {
    const readMoreBtn = description.querySelector(".read-more");
    if (description.clientHeight > 139) {
      description.style.maxHeight = "140px";
      readMoreBtn.style.display = "block";
      description.classList.add("collapsed");
      readMoreBtn.addEventListener("click", () => {
        if (description.classList.contains("collapsed")) {
          description.classList.remove("collapsed");
          description.style.maxHeight = "1000px";
          readMoreBtn.innerHTML = "Czytaj mniej";
          setTimeout(() => {
            description.style.height = description.clientHeight + 30 + "px";
          }, 100);
        } else {
          description.classList.add("collapsed");
          description.style.maxHeight = "140px";
          description.style.height = "unset";
          readMoreBtn.innerHTML = "Czytaj więcej";
        }
      });
    }
  });
}

const productOpinions = document.querySelector(".product-opinions");

if (productOpinions) {
  const productOpinionsSlider = new Swiper(".product-opinions__items", {
    modules: [Navigation],
    loop: true,
    slidesPerView: 1,
    spaceBetween: 24,

    navigation: {
      nextEl: ".product-opinions-next-btn",
      prevEl: ".product-opinions-prev-btn",
    },
    breakpoints: {
      600: {
        slidesPerView: 2,
      },
      900: {
        slidesPerView: 3,
      },
    },
  });
}
